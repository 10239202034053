import { useForm as useTarcltdForm, type Schema } from '@tarcltd/form-vue'

export default function useForm(
  schema: Schema | Record<string, unknown>,
  options: Record<string, unknown> = {},
) {
  const ret = useTarcltdForm(schema as Schema, options)
  const isProcessing = ref(false)
  const touched = ref(new Set<string>())
  const left = ref(new Set<string>())
  const errorState = computed(() => {
    const parsed = ret.schema.safeParse(ret.state)
    const formErrors: Record<string, string> = {}

    if (Array.isArray(parsed.error?.issues)) {
      for (const issue of parsed.error.issues) {
        formErrors[issue.path[0]] = issue.message
      }
    }

    return formErrors
  })

  function onFocus(fieldKey: string) {
    left.value.delete(fieldKey)
  }

  function onBlur(fieldKey: string) {
    left.value.add(fieldKey)
  }

  function resetForm() {
    touched.value.clear()
    left.value.clear()
    ret.reset()
  }

  watch(ret.state, (value) => {
    for (const key in value) {
      touched.value.add(key)
    }
  })

  return {
    ...ret,
    isProcessing,
    errorState,
    touched,
    left,
    onFocus,
    onBlur,
    resetForm,
  }
}
